@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-project-1 {
    background-image: url('../public/img/project-1.png');
}
.bg-project-2 {
    background-image: url('../public/img/project-2.png');
}
.bg-project-3 {
    background-image: url('../public/img/project-3.png');
}
.bg-project-4 {
    background-image: url('../public/img/project-4.png');
}