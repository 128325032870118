@tailwind components;
@tailwind utilities;
@tailwind base;

.section {
    padding-left: 10%;
    padding-right: 10%;
}


html {
    scroll-behavior: smooth;
}